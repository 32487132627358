/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Google Sans"),
    url("./assets/fonts/ProductSans-Regular.woff") format("woff");
}

@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Google Sans"),
    url("./assets/fonts/ProductSans-Thin.woff") format("woff");
}

@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 100;
  src: local("Google Sans"),
    url("./assets/fonts/ProductSans-Light.woff") format("woff");
}

@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 500;
  src: local("Google Sans"),
    url("./assets/fonts/ProductSans-Medium.woff") format("woff");
}

@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 900;
  src: local("Google Sans"),
    url("./assets/fonts/ProductSans-Black.woff") format("woff");
}

@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Google Sans"),
    url("./assets/fonts/ProductSans-Bold.woff") format("woff");
}
body,
html {
  font-family: "Google Sans", sans-serif !important;
  background-color: #fff;
}
.face-camera-wrap {
  background-color: #0b101b !important;
  background-repeat: no-repeat;
  background-size: 40px 40px;
  width: 100%;
  height: 100%;
  transition: width 2s ease, height 2s ease, border-radius 2s ease;
}

.face-camera {
  transform: scaleX(-1);
}
.minimize-camera {
  width: 290px;
  height: 290px;
  border-radius: 50%;
  margin: 0 auto;
  padding: 0px;
}

.minimize-camera video {
  transition: border-radius 2s ease;
  border-radius: 50%;
  transform: scaleX(-1);
}

.small-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
  padding: 0px;
}

.small-circle video {
  transition: border-radius 2s ease;
  border-radius: 50%;
  transform: scaleX(-1);
}

.small-circle-document {
  width: 120px;
  height: 100px;
  border-radius: 30%;
  margin: 0 auto;
  padding: 0px;
}

.small-circle-document video {
  transition: border-radius 2s ease;
  border-radius: 30%;
}

.dotted-border {
  position: relative;
}

.face-canvas {
  position: absolute;
  left: 49%;
  top: 51.5%;
  transform: translate(-50%, -50%);
}

.document-canvas {
  position: absolute;
  z-index: 999999;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  padding: 15px;
}

.blur-camera {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.greenOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  border: 3px solid green;
  background-color: #0080005e;
}

.passkeyList {
  list-style: inside !important;
}

.flipCard {
  transition: all 0.5s;
}
.flipAnimation {
  transition: all 0.5s;
  transform: rotateY(360deg);
}

.range-slider .range-slider__thumb, .range-slider .range-slider__range{
  background: #0c4888;
}

#experiment {
  perspective: 2000px;
  height: 100%;
  width: 100%;
}

.cube {
  position: relative;
  height: 100%;
  width: 100%;
  transform-style: preserve-3d;
}
.face {
  position: absolute;
  height: 100%;
  width: 100%;
  transition: transform 1s linear, opacity 1s linear;
}
.cube .front {
  transform:  rotateY(-90deg) translateX(-50%) rotateY(90deg) translateX(50%) rotateY(90deg);
  transform-origin: center center;
  opacity: 0;
}

.cube .side {
  transform:  rotateY(-90deg) translateX(-50%) rotateY(0deg) translateX(50%) rotateY(90deg);
}

.cube .front.active {
  transform:  rotateY(-90deg) translateX(-50%) rotateY(0deg) translateX(50%) rotateY(90deg);
  opacity: 1;
}

.cube .side.active {
  transform:  rotateY(-90deg) translateX(-50%) rotateY(-90deg) translateX(50%) rotateY(90deg);
  opacity: 0;
}

.sigCanvas {
  background-color: #cccccc8f;
  border: 2px solid #8c8a8a;
  margin-top: 20px;
  margin-bottom: 20px;
}

.provePhoneInput input {
  font-size: 38px !important;
  padding-left: 5rem;
  height: 70px;
}

.provePhoneInput span {
  font-size: 30px;
}

.provePhoneInput .flagWrap {
  margin-top: -2px;
  /*margin-left: -2px;*/
}

.dobInput input {
  font-size: 30px !important;
  padding-left: 5rem;
  height: 70px;
  width: 100%;
}

.frontDlAnimation {
  /*perspective: 1000px;*/
  text-align: center;
  /*transition: transform 0.6s;*/
  /*transform-style: preserve-3d;*/
  /*animation: dlFlip 1s ease-in-out forwards;*/
}

@keyframes dlFlip {
  0% {
    transform: rotateY(180deg);
    /* scale: 0.8; */
  }

  100% {
    transform: rotateY(-0deg);
    scale: 1;
  }
}

.backDlAnimation {
  /*perspective: 1000px;*/
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  animation: dlBackFlip 1s ease-in-out forwards;
}

@keyframes dlBackFlip {
  0% {
    transform: rotateY(180deg);
    /* scale: 0.8; */
  }

  100% {
    transform: rotateY(-0deg);
    scale: 1;
  }
}
/* .frontDlAnimation:hover {
  transform: rotateY(-180deg);
} */

@media only screen and (min-width: 1950px) {
  .main-layout {
    height: 100vh;
  }
  .inner-layout {
    margin-top: 6rem;
  }
}

@media only screen and (max-width: 767px) {
  input {
    font-size: 16px !important;
  }
  html, body {
    overflow-x: hidden;
  }
}

.camera-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0px;
}

.camera-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* Semi-transparent overlay */
}

.recognition-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 72%;
  height: 35%;
  transform: translate(-50%, -50%);
  transition: all .5s;
}

.recognition-box1 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 72%;
  height: 35%;
  transform: translate(-50%, -50%);
  transition: all 2s;
}

.corner {
  position: absolute;
  border: 4px solid #000;
  animation: corner-animation 1s forwards 1100ms;
  opacity: 0;
  /* border-radius: px; */
}


.cornerWhite {

  border: 4px solid #fff;

}

.greenBorder, .white-border.greenBorder {
  border-color: #1fd31f !important;
}


.corner.top-left {
  top: 0;
  left: 0;
  border-right: none;
  border-bottom: none;
  border-top-left-radius: 10px;
}

.corner.top-right {
  top: 0;
  right: 0;
  border-left: none;
  border-bottom: none;
  border-top-right-radius: 10px;
}

.corner.bottom-left {
  bottom: 0;
  left: 0;
  border-right: none;
  border-top: none;
  border-bottom-left-radius: 10px;
}

.corner.bottom-right {
  bottom: 0;
  right: 0;
  border-left: none;
  border-top: none;
  border-bottom-right-radius: 10px;
}

@keyframes corner-animation {
  /* 0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  } */
  0% {
    width: 0px;
    height: 0px;
    opacity: 0;
  }
  /* 50% {
    width: 20px;
    height: 20px;
  } */
  100% {
    width: 40px;
    height: 40px;
    opacity: 1;
  }
}

@keyframes custom-scale {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

.animate-custom-scale {
  animation: custom-scale 1s ease-in-out;
}

.face-dot {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #000; /* Black color for the dots */
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}

/* Animations for each dot */
.dot-eye-left {
  top: 37%;
  left: 30%;
  animation: move-eye-left 2s infinite ease-in-out 1500ms;
  opacity: 0;
}

.dot-eye-right {
  top: 37%;
  left: 64%;
  animation: move-eye-right 2.5s infinite ease-in-out 1500ms;
  opacity: 0;
}

.dot-nose {
  top: 45%;
  left: 47%;
  animation: move-nose 3s infinite ease-in-out 1500ms;
  opacity: 0;
}

.dot-mouth-left {
  top: 55%;
  left: 30%;
  animation: move-mouth-left 2.2s infinite ease-in-out 1500ms;
  opacity: 0;
}

.dot-mouth-right {
  top: 55%;
  left: 60%;
  animation: move-mouth-right 2.8s infinite ease-in-out 1500ms;
  opacity: 0;
}

/* Keyframes for individual dot movements */

/* Eye Left Animation */
@keyframes move-eye-left {
  0% {
    transform: translateX(0px);
    opacity: 0;
  }
  25% {
    transform: translateX(8px);
    opacity: 1;
  }
  50% {
    transform: translateX(0px);
    opacity: 1;
  }
  75% {
    transform: translateX(-8px);
    opacity: 1;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

/* Eye Right Animation */
@keyframes move-eye-right {
  0% {
    transform: translateX(0px);
    opacity: 0;
  }
  25% {
    transform: translateY(10px);
    opacity: 1;
  }
  50% {
    transform: translateY(0px);
    opacity: 1;
  }
  75% {
    transform: translateY(-10px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

/* Nose Animation */
@keyframes move-nose {
  0% {
    transform: translate(0px, 0px);
    opacity: 0;
  }
  50% {
    transform: translate(6px, -6px);
    opacity: 1;
  }
  100% {
    transform: translate(0px, 0px);
    opacity: 1;
  }
}

/* Mouth Left Animation */
@keyframes move-mouth-left {
  0% {
    transform: translateY(0px);
    opacity: 0;
  }
  50% {
    transform: translateY(10px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

/* Mouth Right Animation */
@keyframes move-mouth-right {
  0% {
    transform: translateX(0px);
    opacity: 0;
  }
  50% {
    transform: translateX(-10px);
    opacity: 1;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

.svg-success {
  display: inline-block;
  vertical-align: top;
  height: 200px;
  width: 200px;
  opacity: 1;
  overflow: visible;
}

@keyframes success-tick {
  0% {
    stroke-dashoffset: 16px;
    opacity: 1;
  }
  100% {
    stroke-dashoffset: 31px;
    opacity: 1;
  }
}

@keyframes success-circle-outline {
  0% {
    stroke-dashoffset: 72px;
    opacity: 1;
  }
  100% {
    stroke-dashoffset: 0px;
    opacity: 1;
  }
}

@keyframes success-circle-fill {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.svg-success .success-tick {
  fill: none;
  stroke-width: 0.5px;
  stroke: #fff;
  stroke-dasharray: 15px, 15px;
  stroke-dashoffset: -14px;
  animation: success-tick 450ms ease 1500ms forwards;
  opacity: 0;
}

.svg-success .success-circle-outline {
  fill: none;
  stroke-width: 0.5px;
  stroke: #fff;
  stroke-dasharray: 72px, 72px;
  stroke-dashoffset: 72px;
  animation: success-circle-outline 300ms ease-in-out 1000ms forwards;
  opacity: 0;
}

.svg-success .success-circle-fill {
  fill: transparent;
  stroke: none;
  opacity: 0;
  animation: success-circle-fill 300ms ease-out 1000ms forwards;
}

/* Can't animate stroke-dashoffset on IE 10 and 11, just show svg instead */
@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .svg-success .success-tick {
    stroke-dasharray: 0;
    stroke-dashoffset: 0;
    animation: none;
    opacity: 1;
  }

  .svg-success .success-circle-outline {
    stroke-dasharray: 0;
    stroke-dashoffset: 0;
    animation: none;
    opacity: 1;
  }

  .svg-success .success-circle-fill {
    animation: none;
    opacity: 1;
  }
}

.successAnimationNew {
  animation: success-animation-new 400ms ease-out 300ms forwards;
  opacity: 0;
}


@keyframes success-animation-new {
  0% {
    width: 0px;
    height: 0px;
    /* scale: 0; */
    opacity: 0;
    border-radius: 100%;
  }
  20% {
    width: 80px;
    height: 80px;
    /* scale: 0; */
    opacity: 1;
    border-radius: 100%;
  }
  40% {
    width: 160px;
    height: 160px;
    /* scale: 0; */
    opacity: 1;
    border-radius: 100%;
  }
  60% {
    width: 320px;
    height: 320px;
    /* scale: 0; */
    opacity: 1;
    border-radius: 100%;
  }
  80% {
    width: 700px;
    height: 700px;
    /* scale: 0; */
    opacity: 1;
    border-radius: 100%;
  }
  100% {
    /* scale: 1.5; */
    width: 4000px;
    height: 4000px;
    opacity: 1;
    border-radius: 100%;
  }
}

.successAnimationNewv2 {
  animation: success-animation-new-v2 400ms ease-out 300ms forwards;
  opacity: 0;
}


@keyframes success-animation-new-v2 {
  0% {
    width: 0px;
    height: 0px;
    /* scale: 0; */
    opacity: 0;
    border-radius: 100%;
  }
  20% {
    width: 80px;
    height: 80px;
    /* scale: 0; */
    opacity: 1;
    border-radius: 80%;
  }
  40% {
    width: 300px;
    height: 300px;
    /* scale: 0; */
    opacity: 1;
    border-radius: 60%;
  }
  60% {
    width: 400px;
    height: 400px;
    /* scale: 0; */
    opacity: 1;
    border-radius: 40%;
  }
  80% {
    width: 500px;
    height: 500px;
    /* scale: 0; */
    opacity: 1;
    border-radius: 30%;
  }
  100% {
    /* scale: 1.5; */
    width: 600px;
    height: 705px;
    opacity: 1;
    border-radius: 0%;
  }
}

@media only screen and (max-width:767px) {
  @keyframes success-animation-new-v2 {
    0% {
      width: 0px;
      height: 0px;
      /* scale: 0; */
      opacity: 0;
      border-radius: 100%;
    }
    20% {
      width: 80px;
      height: 80px;
      /* scale: 0; */
      opacity: 1;
      border-radius: 80%;
    }
    40% {
      width: 300px;
      height: 300px;
      /* scale: 0; */
      opacity: 1;
      border-radius: 60%;
    }
    60% {
      width: 400px;
      height: 400px;
      /* scale: 0; */
      opacity: 1;
      border-radius: 40%;
    }
    80% {
      width: 500px;
      height: 500px;
      /* scale: 0; */
      opacity: 1;
      border-radius: 30%;
    }
    100% {
      /* scale: 1.5; */
      width: 600px;
      height: 100vh;
      opacity: 1;
      border-radius: 0%;
    }
  }

}


/* Change the white to any color */
.phone-wrap input:-webkit-autofill,
.phone-wrap input:-webkit-autofill:hover,
.phone-wrap  input:-webkit-autofill:focus,
.phone-wrap  input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #161b25 inset !important;
}

.phone-wrap input:-webkit-autofill{
  -webkit-text-fill-color: #fff !important;
}
.instructionAnimation {
  /* transition: all 2s; */
  z-index: 9999999;
  animation: instructionAnimation 1000ms ease-out 800ms forwards;
  transform: translateY(705px);
}

@keyframes instructionAnimation {
  0% {
    transform: translateY(705px);
  }
  100% {
    transform: translateY(0px);
  }
}

.userInfoAnimation {
  /* transition: all 2s; */
  z-index: 9999999;
  animation: userInfoAnimation 400ms ease-out 300ms forwards;
  transform: translateX(705px);
}

@keyframes userInfoAnimation {
  0% {
    transform: translateX(705px);
  }
  100% {
    transform: translateX(0px);
  }
}

.scanner-frame-intro {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 70px auto;
}

.scanner-frame-intro .scanner-corner {
  position: absolute;
  width: 30px;
  height: 30px;
  border: 3px solid #fff;
}

/* Top left scanner-corner */
.scanner-frame-intro .scanner-corner.top-left {
  top: 0;
  left: 0;
  border-right: none;
  border-bottom: none;
}

/* Top right scanner-corner */
.scanner-frame-intro .scanner-corner.top-right {
  top: 0;
  right: 0;
  border-left: none;
  border-bottom: none;
}

/* Bottom left scanner-corner */
.scanner-frame-intro .scanner-corner.bottom-left {
  bottom: 0;
  left: 0;
  border-right: none;
  border-top: none;
}

/* Bottom right scanner-corner */
.scanner-frame-intro .scanner-corner.bottom-right {
  bottom: 0;
  right: 0;
  border-left: none;
  border-top: none;
}

/* Central horizontal line */
.horizontal-line {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #fff;
  animation: scanner-line 5s ease-out 300ms infinite;
  width: 70%;
  left: 30px;
}

.white-border {
  border-color: #fff !important;
}

.phone-wrap input{
  border-radius: 16px;
  border: 2px solid #FFFFFF1A !important;
  background-color: transparent;
  color: #fff;
  outline: none !important;
}

.error-wrap input{
  border-radius: 16px;
  border: 2px solid rgba(255, 0, 0, 0.575) !important;
  background-color: transparent;
  color: #fff;
  outline: none !important;
}

.phone-wrap span, .error-wrap span {
  color: #fff;
}


@keyframes scanner-line {
  0% {
    transform: translateY(-90px);
  }
  30% {
    transform: translateY(0px);
  }
  60% {
    transform: translateY(90px);
  }
  80% {
    transform: translateY(-0px);
  }
  100% {
    transform: translateY(-90px);
  }
}

.pincode-input-container input {
  transform: unset !important;
}

.backdrop-blur-lg {
  backdrop-filter: blur(15px);
}